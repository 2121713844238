import request from '@/util/request'

// 获取 所有牧户列表
export function getUserInfoList(data) {
  return request({
      url: `/bigdata/user/info/page`,
      method: 'post',
      data
  })
}

// 获取 xxx 嘎查的牧户列表
export function getUserList(villageId) {
  return request({
      // url: `/app/animal/bigData/getUserList?villageId=${villageId}&groupName=${groupName}`,
      url: `/app/animal/bigData/getUserList?villageId=${villageId}`,
      method: 'get'
  })
}

// 获取 牧户详情
export function getUserDetail(ownerId) {
  return request({
      url: `/app/animal/bigData/getUserInfo?ownerId=${ownerId}`,
      method: 'get'
  })
}

// 获取 牧户的 存栏 动物信息
export function getBreedingRecordsListByOwnerId(ownerId) {
  return request({
      url: `/app/animal/bigData/getBreedingRecordsListByOwnerId?ownerId=${ownerId}`,
      method: 'get'
  })
}

// 获取 牧户的 防疫 动物信息
export function getRecordsBingListByOwnerId(ownerId) {
  return request({
      url: `/app/animal/bigData/getRecordsBingList?ownerId=${ownerId}`,
      method: 'get'
  })
}

// 获取 动物详情
export function getBreedingRecordsInfo(id) {
  return request({
      url: `/app/animal/bigData/getBreedingRecordsInfo?id=${id}`,
      method: 'get'
  })
}

// 获取 牧户 牲畜 普查
export function getCheckList(data) {
  return request({
      url: `/bigdata/animal/check/list`,
      method: 'post',
      data
  })
}