<template>
    <div class="body_bgi box_1 full bgs_full relative" ref="home_box">
      <!-- header -->
      <div class="header_bgi2 w_full bgs_full flex_row_around ph_20 border_box">
        <div class="w_45_p fw_bold fs_20 text_right ls_10 relative top_30 jian_bian_text_1">
          巴林右旗动物疫病预防控制中心
        </div> 

        <div class="w_110 p_15 border_box">
          <img src="~@/assets/logo.png" class="w_full response_img" alt="">
        </div>

        <div class="w_45_p h_full flex_row_around flex_center">
          <div class="w_70_p h_55 fw_bold mon_text lh_50 color_fff fs_18 text_left relative top_25 jian_bian_text_2">
            ᠪᠠᠭᠠᠷᠢᠨ ᠪᠠᠷᠠᠭᠤᠨ ᠬᠣᠱᠢᠭᠤᠨ᠎ᠤ ᠠᠮᠢᠲᠠᠨ᠎ᠤ ᠬᠡᠮ ᠬᠢᠵᠢᠭ᠎ᠢ ᠰᠡᠷᠭᠡᠢᠯᠡᠨ ᠬᠢᠵᠠᠭᠠᠷᠯᠠᠬᠤ ᠲᠥᠪ
          </div>

          <!-- 天气、气温 -->
          <div class="w_30_p jian_bian_text_1 text_right relative top_20">
            <!-- <AmapWeather /> -->
          </div>
        </div> 
      </div>

      <!-- body -->
      <div class="w_full-60 h_full-130 m_0_auto flex_row_around">
        <!-- 1 -->
        <div class="w_30_p h_full">
          <fang-yi-site :jobId="jobId" />
        </div>

        <!-- 2 -->
        <div class="w_40_p h_full ph_10 border_box flex_column_between">
          <!-- animate-number -->
          <div class="w_full h_49_p relative">
            <div class="w_full flex_row_around flex_center relative z_999">
              <div class="flex_1 cursor flex_column_start title_bgi1 bgs_full">
                <div class="w_full text_center fs_16 jian_bian_text_1 fw_bold">全旗动物存栏</div>
                <div class="w_full fs_24 font_number num_style color_fff text_center">
                  <animate-number from="0" :to="animalCount" :key="animalCount" duration="2000" />
                </div>
              </div>
              <div class="w_1 h_10 bgc_4b7dc3"></div>
              <div class="flex_1 cursor flex_column_start title_bgi1 bgs_full">
                <div class="w_full text_center fs_16 jian_bian_text_1 fw_bold">全旗动物免疫</div>
                <div class="w_full fs_24 font_number num_style color_fff text_center">
                  <animate-number from="0" :to="fangYiCount" :key="fangYiCount" duration="2000" />
                </div>
              </div>
              <div class="w_1 h_10 bgc_4b7dc3"></div>
              <div class="flex_1 cursor flex_column_start title_bgi1 bgs_full" @click="goToFangYiYaunListPage()">
                <div class="w_full text_center fs_16 jian_bian_text_1 fw_bold">全旗防疫员</div>
                <div class="w_full fs_24 font_number num_style color_fff text_center">
                  <!-- <animate-number from="0" :to="fangYiYuanCount" :key="fangYiYuanCount" duration="2000" /> -->
                  <animate-number from="0" :to="198" :key="198" duration="2000" />
                </div>
              </div>
              <div class="w_1 h_10 bgc_4b7dc3"></div>
              <div class="flex_1 cursor flex_column_start title_bgi1 bgs_full" @click="goToMuHuListPage()">
                <div class="w_full text_center fs_16 jian_bian_text_1 fw_bold">全旗养殖户</div>
                <div class="w_full fs_24 font_number num_style color_fff text_center">
                  <animate-number from="0" :to="muHuCount" :key="muHuCount" duration="2000" />
                </div>
              </div>
            </div>

            <!-- 防疫工作 -->
            <div class="w_full flex_row_end absolute top_80 right_10 z_999">
              <div class="fs_26 font_number cursor jian_bian_text_1" @click="showSelectJobBox = true">{{ jobName }}</div>
              <div class="fs_26 relative top_-2">
                <img class="w_30 h_30 cursor" src="~@/assets/images/chart-box/zhen.png" alt="" @click="showSelectJobBox = true">
              </div>
              <!-- <el-select 
                class="select" 
                v-model="jobId" 
                size="small" 
                placeholder="防疫工作" 
              >
                <el-option
                  v-for="(item, index) in jobList" :key="index"
                  :label="item.name"
                  :value="item.id"
                  @click.native="selectJob(item)"
                >
                </el-option>
              </el-select> -->
            </div>

            <!-- 巴林右旗 地图 -->
            <div class="w_full h_90_p relative bottom_5_p z_9">
              <h-map class="full" @clickMap="clickMap" />
            </div>
          </div>

          <!-- 全旗疫苗库存 -->
          <div class="w_full h_49_p p_5 bgs_full chart_box_bgi_1">
            <yi-miao-chart 
              title="疫苗库存统计（疫苗台账）"
              :chartData="vaccinumCountChart" 
              :path="'/storePage'" 
              :isShowUnit="true"
              :jobId="jobId"
              @clickYiMiao="clickYiMiao"
            />
          </div>
        </div>

        <!-- box3 -->
        <div class="w_30_p">
          <cun-lan-mian-yi
            areaCode="150423000000" 
            parentAreaCode="150400000000"
            parentLevel="2"
            :jobId="jobId"
            @changeCount="changeCount"
          />
        </div>
      </div>

      <!-- welcome 欢迎页面 -->
      <div 
        class="full absolute top_0 left_0 welcome_bgi z_999 scroll_box" 
        :class="{ bgi_animate_none: hideWelcome }"
        v-show="isHideWelcome == hideWelcome2"
      >
        <div class="full absolute top_0 left_0">
          <!-- 选择角色 -->
          <div class="w_full select_global_job h_30 text_right p_10">
            <el-select v-model="roleName" placeholder="请选择角色">
              <el-option
                v-for="(item, index) in roleList" :key="index"
                :label="item.name"
                :value="item.id"
                @click.native="selseRole(item)">
              </el-option>
            </el-select>
          </div>

          <div class="w_full h_40_p absolute_center flex_column_around ">
            <div class="w_full h_90 relative animate__animated" :class="{'animate__fadeOutUp': hideWelcome }">
              <div class="w_110 h_110 p_15 absolute_center border_box" >
                <img src="~@/assets/logo.png" class="w_full response_img " alt="">
              </div>
            </div>
            
            <div 
              class="w_full text_center fw_bold fs_40 ls_13 jian_bian_text_1 animate__animated"
              :class="[ hideWelcome ? 'animate__fadeOutUp' : 'animate__fadeInDown']"
            >          
            巴林右旗动物防疫大数据平台
            </div> 

            <div class="h_115 flex_row_around">
              <div 
                class="h_full fw_bold mon_text lh_81 color_fff fs_40 jian_bian_text_2 animate__animated relative left_-6"
                :class="[hideWelcome ? 'animate__fadeOutDown' : 'animate__fadeInUp']">
                ᠪᠠᠭᠠᠷᠢᠨ ᠪᠠᠷᠠᠭᠤᠨ ᠬᠣᠱᠢᠭᠤᠨ᠎ᠤ ᠠᠮᠢᠲᠠᠨ᠎ᠤ ᠬᠡᠮ ᠬᠢᠵᠢᠭ᠎ᠢ ᠰᠡᠷᠭᠡᠢᠯᠡᠬᠦ ᠶᠡᠬᠡ ᠳ᠋ᠠᠢᠲ᠋ᠠ᠎ᠶᠢᠨ ᠲᠠᠪᠴᠠᠩ
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        :visible.sync="showSelectJobBox"
        width="30%"
      >
        <div class="full">
          <div 
            class="p_10 text_center cursor" 
            v-for="(item, index) in jobList" :key="index"
            @click="selectJob(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
  import AmapWeather from '@/components/weather/AmapWeather.vue';
  import HMap from '@/components/HMap/index.vue'

  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components';
  import dataChartMixin from '@/mixins/data-charts.js'

  import { getTownList } from '@/api/base/base.js'
  import { getInventory, getStoreList } from '@/api/fang-yi/yi-miao.js'
  import { getJobList2 } from '@/api/fang-yi/fang-yi.js'
  import { getUserInfoList } from '@/api/mu-hu/mu-hu.js'

  import { filePath } from "@/const"

  export default {
    name: 'Home',
    components: {
      AmapWeather,
      HMap
    },
    mixins: [defMixin, componentsMixin, dataChartMixin],
    data() {
      return {
        filePath: filePath,

        showSelectJobBox: false,

        animalCount: null,
        fangYiCount: null,
        fangYiYuanCount: null,
        muHuCount: null, 

        vaccinumCountChart: {
          unit: '毫升、头份',
          isShowUnit: true,
          data: []
        },

        activeIndex: null,

        townList: [],                   // 巴林右旗 所属 镇、苏木 

        villageList: [],
        vaccinumTypeId: null,

        jobId: null,
        jobName: null,
        jobList: [],
        panDianList: [],

        roleName: null,
        roleList: [],

        isHideWelcome: false,
        hideWelcome2: sessionStorage.getItem('role') || false,
        hideWelcome: false
      }
    },
    computed: {
      getStateJobId() {
        return this.$store.state.jobId
      },
    },
    created() {
      
    },
    async mounted () {
      if (sessionStorage.jobList) {
        this.jobId = JSON.parse(sessionStorage.job).jobId
        this.jobName = JSON.parse(sessionStorage.job).jobName
        this.jobList = JSON.parse(sessionStorage.jobList)
      } else {
        // 获取 防疫 盘点 工作
        await this.getJobList()
      }

      let params = {
        adminAreaCode: '150423000000',
        level: 2,
        page: 1,
        size: 200
      }
      // 获取 所有牧户 数量
      this.getUserInfoList(params)
      
      // 获取仓库列表
      await this.getStoreList()

      // 获取 全旗 镇（苏木）
      await this.getTownList()

      // 获取 巴林右旗 疫苗库存信息
      await this.getInventory(this.jobId)
    },
    destroyed() {

    },
    methods: {
      // 获取
      async getUserInfoList(params) {
        let res = await getUserInfoList(params)
        try {
          if (res.code == 1000) {
            this.muHuCount = res.data.pagination.total
          }
        } catch (err) {
          console.log('获取 userInfo 失败 : ', err);
        }
      },

      // 获取 仓库列表
      async getStoreList() {
        let res = await getStoreList()
        try {
          if (res.code == 1000) {
            let isShowMoNi = window.sessionStorage.getItem('isShowMoNi')
            if (isShowMoNi) {
              this.roleList = res.data.filter(item => item.isPersonal == 0)
            } else {
              this.roleList = res.data.filter(item => item.isPersonal == 0 && item.name !== '模拟防疫站')
            }
          }
        } catch (err) {
          console.log('获取 仓库失败 : ', err);
        }
      },

      // 获取 防疫工作、盘点 列表
      async getJobList() {
        let res = await getJobList2()
        try {
          if (res.code == 1000) {
            let jobList = res.data.filter(item => item.isType == 1)
            let panDianList = res.data.filter(item => item.isType == 0)

            this.jobList = jobList
            this.panDianList = panDianList

            let jobId = null
            let jobName = null

            if (sessionStorage.job) {
              let sessionJob = JSON.parse(sessionStorage.getItem('job'))

              this.jobId = sessionJob.jobId
              this.jobName = sessionJob.jobName
            } else {
              for (let i in res.data) {
                if (res.data[i].active == 1 && res.data[i].isType == 1) {
                  jobId = res.data[i].id
                  jobName = res.data[i].name
                  this.jobId = jobId
                  this.jobName = jobName
                }
              }

              sessionStorage.setItem('job', JSON.stringify({jobId, jobName}))
            }
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 巴林右旗的 疫苗库存信息
      async getInventory(jobId) {
        let params = {
          storeId: 1,
          jobId,
          page: 1,
          size: 1000
        }
        let res = await getInventory(params)
        try {
          if (res.code == 1000) {
            this.vaccinumCountChart = {
              unit: '毫升、头份',
              isShowUnit: true,
              data: this.formatYiMiaoList(res.data.list)
            }
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 监听 选择 防疫工作
      selectJob(item) {
        this.jobId = item.id
        this.jobName = item.name

        this.showSelectJobBox = false
        let sessionJob = {jobId: item.id, jobName: item.name}

        sessionStorage.setItem('job', JSON.stringify(sessionJob))
        
        // 获取 这个 年度的 疫苗库存信息
        this.getInventory(item.id)
      },

      // 监听 地区的存栏防疫 总数
      changeCount(obj) {
        this.animalCount = obj.animalCount
        this.fangYiCount = obj.fangYiCount
      },
  
      // 格式化 图表所用的 数据
      formatYiMiaoList(list) {
        let newList = []
        let item = {}

        if (list && list.length) {
          for (let i in list) {
            item = { 
              id: list[i].goodsId,
              name: list[i].goodsName,
              value: list[i].quantity,
              unit: '毫升',
            }
            newList.push(item)
          }
        }

        return newList
      },

      // 监听 点击 地图
      clickMap(val) {
        let townId = null
        let level = 3

        this.townList.forEach(item => {
          if (item.name == val) {
            townId = item.id

            // 跳转到 xxx镇 防疫统计页面
            this.$router.push({
              path: `/townFyPage/${townId}/${level}/${item.name}`
            })
          }
        })
      },

      // 获取 巴林右旗 镇 苏木
      async getTownList() {
        let blyq_countyId = '150423000000'

        let res = await getTownList(blyq_countyId)
        try {
          if (res.code == 1000) {
            this.townList = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 跳转到防疫员页面
      goToAntiepidemicPersonListPage(id) {
        this.$router.push(`/fangYiYuanPage/${id}`)
      },

      // 跳转页面 
      goToPage(val) {
        this.$router.push({
          path: val
        })
      },

      // 跳转到 【防疫员】列表 页面
      goToFangYiYaunListPage() {
        this.$router.push(`/fangYiYuanList`)
      },

      // 跳转到 【牧户】列表 页面
      goToMuHuListPage() {
        this.$router.push(`/muHuList`)
      },

      // 跳转到 疫苗 详情页
      clickYiMiao(val) {
        this.goToPage('/storePage')
      },

      // 进入大屏时 选择角色
      selseRole(item) { 
        sessionStorage.setItem('role', JSON.stringify(item))

        if (item.id !== 1) {
          this.hideWelcomePage()

          this.$router.push({
            path: `/erpSite/${item.id}/${item.name}/${item.managerId}`
          })
        } else {
          this.hideWelcomePage()
        }
      },

      hideWelcomePage() {
        this.hideWelcome = true

        setTimeout(() => {
          this.isHideWelcome = true
        }, 6000)
      }
    }
  }
</script>

<style>
.welcome_box_opacity { opacity: 60% !important; }

.select_global_job {
  /* 下拉框 */
  .el-select-dropdown{
    border: none;
    background-color: rgba(1, 28, 82, 0.8);
  }
  /* 输入框 */
  .el-input__inner{
    color:#eee;
    border-color: #00fff6;
    background-color: rgba(1, 28, 82, 0.8);
  }
  /* 聚焦时的样式 */
  .el-select .el-input.is-focus .el-input__inner{
    border-color: #0B61AA;
    background-color: rgba(1, 28, 82, 0.8);
    color:#00D3E9;
  }
  /* 下拉框选中 */
  .el-select-dropdown__item{
    color: #eee;
  }
  /* 鼠标经过下拉框 */
  .el-select-dropdown__item.hover, 
  .el-select-dropdown__item:hover{
    color:#00D3E9;
    background-color: #0F3360;
  }
}
</style>
